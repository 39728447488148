@import "mixin";
// Color Variable
$white: #ffffff;
$black: #000000;

$primary: #36B4B0 !default;
$secondary : #2D3436 !default;

$danger : #E35230;
$sucess : #B8CE46;
$warning : #FCBA08;
$orange : #FF871F;
$green: green;
$lightgreeen: #B8CE46;

$color1 : #5E686B;
$color2 : #4D6065;
$color3 : #A7B0B2;
$color4 : #A2B3B9;
$color5 : #DADEDE;
// $color3 : #707070;
// $color4 : #29393E;
// $color5 : #EAEFF0;

// media quary
$lg: rem(1199);
$md: rem(991);
$sm: rem(767);
$xs: rem(575);

// Font Family
%Avenir-Light {
    font-family: 'Avenir-Light';
}
%Avenir {
    font-family: 'Avenir';
}
%Avenir-Medium {
    font-family: 'Avenir-Medium';
}
%Avenir-Heavy {
    font-family: 'Avenir-Heavy';
}
%Avenir-Black {
    font-family: 'Avenir-Black';
}

%transition {
    @include prefix( transition, all 0.3s ease-in-out);
}
.border-r20 {
    @include prefix(border-radius, rem(20));
}
.border-r10 {
    @include prefix(border-radius, rem(10));
}