.d-table{display:table;}
.d-block{display:block;}
.d-none{display:none !important;}
.d-inline-block{display:inline-block;}
.d-grid{display:grid;}
.d-table-row{display:table-row;}
.d-table-cell{display:table-cell;}
.d-table-column{display:table-column;}

/*--- Flex ---*/
.d-flex{display:-ms-flexbox;display:-webkit-box;display:flex;}
.flex-wrap{-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap;}
.flex-nowrap{-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
.align-items-start{-ms-flex-align:start;-webkit-box-align:start;align-items:flex-start;}
.align-items-center{-ms-flex-align:center;-webkit-box-align:center;align-items:center;}
.align-items-end{-ms-flex-align:end;-webkit-box-align:end;align-items:flex-end;}
.align-items-baseline{-ms-flex-align:baseline;align-items:baseline;}
.align-items-stretch{-ms-flex-align:stretch;align-items:stretch;}
.align-content-start{-ms-flex-line-pack:start;align-content:flex-start;}
.align-content-center{-ms-flex-line-pack:center;align-content:center;}
.align-content-end{-ms-flex-line-pack:end;align-content:flex-end;}
.align-content-between{-ms-flex-line-pack:justify;align-content:space-between;}
.align-content-around{-ms-flex-line-pack:distribute;align-content:space-around;}
.align-self-start{-ms-flex-item-align:start;align-self:start;}
.align-self-center{-ms-flex-item-align:center;align-self:center;}
.align-self-end{-ms-flex-item-align:end;align-self:end;}
.align-self-baseline{-ms-flex-item-align:baseline;align-self:baseline;}
.align-self-stretch{-ms-flex-item-align:stretch;align-self:stretch;}
.justify-content-start{-ms-flex-pack:start;-webkit-box-pack:start;justify-content:flex-start;}
.justify-content-center{-ms-flex-pack:center;-webkit-box-pack:center;justify-content:center;}
.justify-content-end{-ms-flex-pack:end;-webkit-box-pack:end;justify-content:flex-end;}
.justify-content-between{-ms-flex-pack:justify;-webkit-box-pack:justify;justify-content:space-between;}
.justify-content-around{-ms-flex-pack:distribute;-webkit-box-pack:distribute;justify-content:space-around;}

.flex-column{-ms-flex-direction:column;-webkit-box-orient:vertical;flex-direction:column;}
.flex-row{-ms-flex-direction:row;-webkit-box-direction:horizontal;flex-direction:row;}
.flex-row-reverse {-webkit-box-orient: horizontal; -webkit-box-direction: reverse; -ms-flex-direction: row-reverse; flex-direction: row-reverse;}
.flex-column-reverse{-ms-flex-direction:column-reverse;flex-direction:column-reverse;}
.flex-column,.flex-row{-webkit-box-direction:normal;}
.flex-1{-ms-flex:1;-webkit-box-flex:1;flex:1;}

.row {display:-ms-flexbox;display:-webkit-box;display:flex;-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap; margin-right: -15px; margin-left: -15px;}
[class^="col-"] {position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
[class^="column"] {position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px;}
.row.space-6 {margin-right: -6px; margin-left: -6px;}
.row.padding-10 [class^="column"] {padding-top:10px;padding-bottom: 10px;}
.row.space-6 [class^="col-"] {padding-top:6px;padding-bottom: 6px;padding-right: 6px; padding-left: 6px;}

.row.space-30 {margin-right: -30px; margin-left: -30px;}
.row.space-30 [class^="column-"] {padding-top:10px;padding-bottom: 10px;padding-right: 30px; padding-left: 30px;}

.full-column {-ms-flex:0 0 100%;-webkit-box-flex:0 0 100%;flex:0 0 100%;max-width: 100%;}
.half-column {-ms-flex:0 0 50%;-webkit-box-flex:0 0 50%;flex:0 0 50%;max-width: 50%;}
.eight-column {-ms-flex:0 0 64%;-webkit-box-flex:0 0 64%;flex:0 0 64%;max-width: 64%;}
.four-column {-ms-flex:0 0 36%;-webkit-box-flex:0 0 36%;flex:0 0 36%;max-width: 36%;}

.col-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
.col-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
.col-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
.col-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
.col-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
.col-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
.col-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
.col-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
.col-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
.col-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
.col-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
.col-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

@media (min-width: 576px) {
    .col-sm-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-sm-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-sm-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-sm-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-sm-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-sm-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-sm-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-sm-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-sm-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-sm-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-sm-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-sm-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 768px) {
    .col-md-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-md-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-md-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-md-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-md-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-md-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-md-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-md-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-md-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-md-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-md-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-md-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 992px) {
    .col-lg-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-lg-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-lg-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-lg-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-lg-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-lg-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-lg-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-lg-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-lg-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-lg-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-lg-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-lg-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 1200px) {
    .col-xl-1 {-ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
    .col-xl-2 {-ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
    .col-xl-3 {-ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
    .col-xl-4 {-ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    .col-xl-5 {-ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
    .col-xl-6 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
    .col-xl-7 {-ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
    .col-xl-8 {-ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
    .col-xl-9 {-ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
    .col-xl-10 {-ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
    .col-xl-11 {-ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
    .col-xl-12 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
}

@include responsive(lg) {
    .row.space-30 {margin-right: -15px; margin-left: -15px;}
    .row.space-30 [class^="column-"] {padding-top:10px;padding-bottom: 10px;padding-right: 15px; padding-left: 15px;}
}