
* {
    margin:0px;
    padding:0px;
    border:none;
    outline:none;
    @include prefix (box-sizing, border-box);
    -webkit-tap-highlight-color: transparent;
}
html {
    @include prefix (text-size-adjust, none);
}
body {
    font-size: rem(16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color : $color1;
    @extend %Avenir;
}
img, picture {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
a {
    text-decoration: none;
    &:hover,&:focus {
        @extend a;
    }
}
button {
    outline: 0;

    &:hover, &:focus {
        @extend button;
    }
}
h1, h2, h3, h4, h5, h6, strong, b {
    margin: 0;
    font-weight: normal;
    line-height: normal;
}
p {
    margin: 0;
    line-height: normal;
}
ul,ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
svg {
    max-width: 100%;
    vertical-align: middle;
    fill: currentColor;
}
div,aside,figure,figcaption, label {
    margin: 0;
}

input,textarea,select{
    @include prefix (appearance, none);
}

::-moz-selection {
    background: $primary;
    color: $white;
}
::selection {
    background: $primary;
    color: $white;
}
::-webkit-input-placeholder {color: $color3;opacity: 1;}
:-moz-placeholder { color: $color3;opacity: 1;}
::-moz-placeholder { color: $color3;opacity: 1;}
:-ms-input-placeholder {color: $color3;opacity: 1;}
@-moz-document url-prefix() {
    select {
       text-indent: rem(-2);
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 rem(1000) $white inset !important;
}
::-webkit-search-cancel-button {
    display: none;
}

// Fonts Size
h1 {font-size: rem(40)}
h2 {font-size: rem(30)}
h3 {font-size: rem(26)}
h4 {font-size: rem(20)}
h5 {font-size: rem(18)}
h6 {font-size: rem(16)}
p {font-size: rem(16)}

.mb-10 {margin-bottom: 10px;}

.address {font-style: normal;}

@media only screen and (max-width: 1024px){
    body {
        font-size: rem(15);
    }
    // Fonts Size
    h1 {text-align: center;font-size: rem(30);}
    h2 {font-size: rem(24);}
    h3 {font-size: rem(20);}
    h4 {font-size: rem(18);}
    h5 {font-size: rem(16);}
    // h6 {font-size: rem(15);}
}