@import 'scss/variable';
@import 'scss/mixin';
@import 'scss/reset';

@import 'scss/component/flex';

.container {
	width: 1260px;
	max-width: 100%;
	padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
.min-h100 {
    min-height: 100vh;
}
.w-100 {
    width: 100%;
}
.bg-light-theme {
    background-color: #F7FAFA;
}
.bg-theme {
    background-color: #36B4B0;
}
.bg-gray {
    background-color: #F5F7F7;
}
.text-danger {
    color: $danger;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.position-relative {
    position: relative;
}

.success-message {
	color: #36B4B0;
	margin-bottom: 10px;
	display: inline-block;
    width: 100%;
    font-family: 'Avenir-Heavy';
}
.error-message {
	color: #E35230;
	margin-bottom: 10px;
	display: inline-block;
    width: 100%;
    font-family: 'Avenir-Heavy';
}

.ShapOne1{fill:$white;}
.ShapOne2{fill:#E2F4F4;}
.ShapOne3{fill:#F7FAFA;}

.IconClassification2{fill:#93E2DF;}
.IconClassification3{fill:#586B6E;}
.IconClassification4{fill:#D9E9EB;}

.Icon3DModel1{opacity:0.502;}
.Icon3DModel2{opacity:0.303;}
.Icon3DModel3{opacity:0.148;}

.IconHealing2{fill:#C3CCD3;}

.IconTreatment1{opacity:0.2;}
